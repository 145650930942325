// ==========================================================================
// Materialize variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  1b. Global
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Forms
//  11. Global
//  12. Grid
//  13. Navigation Bar
//  14. Side Navigation
//  15. Photo Slider
//  16. Spinners | Loaders
//  17. Tabs
//  18. Tables
//  19. Toasts
//  20. Typography
//  21. Footer
//  22. Flow Text
//  23. Collections
//  24. Progress Bar
// ==========================================================================

// 1. Colors
.red {
    background-color: #f44336 !important
}

.red.darken-4 {
    background-color: #b71c1c !important
}

.green {
    background-color: #4caf50 !important
}

.black {
    background-color: #000 !important
}

.black-text {
    color: #000 !important
}

.white {
    background-color: #fff !important
}

.white-text {
    color: #fff !important
} 

// Global 
html {
    font-family: 'Montserrat', 'MontFallback', Arial, Verdana, sans-serif;
}

@font-face {
    font-family: "MontFallback";
    /* These values are based on Fair Play's table data */
    ascent-override: 96.8%;
    descent-override: -25.1%;
    line-gap-override: 0%;  
    advance-override: 164.2%;
    src: local("Arial");
  }

body {
    background-color: #f3f4f6;
}
.wrapper {
    width: 80%;
    margin: 0 auto;
}
/* Add background image
body {
    background-color: #f3f4f6;
    border: 10px solid #f3f4f6;
    background-image: url("/img/about_banner-logo.png");
    background-repeat: apace;
    // background-position: right 10px top 20px;
    // background-size: cover;
    padding-right: 2rem;
    background-origin: content-box;
}
.body-container {
    background-color: #f3f4f6;
}

*/

@media ( max-width: 62em ) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 62rem !important;
        margin: 0 auto !important;
    }
}

.mt1 {
    margin-top: 1rem;
}
// Global Head

h1 {
    color: $primary-color;
    font-weight: 500;
    margin: 1.75rem 0 0 0;
    line-height:110%;
}
h2 {
    color: $primary-color;
    font-weight: 400;
}

h1 strong, h2 strong {
    font-weight: 700;
}

h1.product-name {
    font-size: 2.5rem;
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
}

h2.product-heading-2 {
    font-size: 2.8125rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 0;
    color: $primary-color;
}
#secproddescrip h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

// Grid
.flex-grid {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    align-items: stretch !important;
}

.flexbox-item {
    display: flex !important;
    flex: 1 !important;
    // flex-basis: 23%;
    // min-width: 19rem;
    margin: .5rem;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
}

.flexbox-item-tab {
    display: flex !important;
    margin: .5rem;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: stretch;
}

.flexbox-basis-66 {
    flex-basis: 66%;
    min-width: 65%;
    width: 65%;
}
.flexbox-basis-50 {
    flex-basis: 48% !important;
    // width: 49% !important;
    min-width: 48% !important;
}
.flexbox-basis-33 {
    flex-basis: 30% !important;
    width: 32% !important;
    min-width: 30% !important;
}
.flexbox-basis-25 {
    flex-basis: 23%;
    width: 23%;
    min-width: 23%;
}

@media only screen and (min-width: 62.0625em) {
    .flexbox-basis-25 {
        flex-basis: 23%;
        width: 23%;
        min-width: 23%;
    } 
}

@media only screen and (max-width: 62em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 50%;
        width: 50%;
        min-width: 50%;
    } 
}
@media only screen and (max-width: 48em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 48%;
        width: 48%;
        min-width: 48%;
    } 
}

@media only screen and (max-width: 37.5em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-item {
        margin: .5rem 0 0 0;
    }
}

@media screen and (min-width: 24.5625em) and (max-width: 47.9375em) {
    .flexbox-basis-66 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
    .flexbox-basis-33 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-basis-25 {
        flex-basis: 100%;
        width: 100%;
        min-width: 100%;
    }
    .flexbox-item {
        margin: .5rem 0 0 0;
    }
}

// Typography
p {
    font-weight:400;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-rendering: optimizeLegibility;
}
.card .card-content p {
    margin: 1em 0 1em 0;
    line-height: 1.5em;
    font-size: 1.2em;
    font-weight: 400;
}

p.taxonomy {
    color: lighten($primary-color, 10%);
    font-size: 1.125rem;
    font-weight: 600;
}
.small-font-size {
    font-size: $small-font-size;
}
.text-small {
    font-size:.8rem; color:#888;
}
.has-error {
    border: .0625 solid $secondary-color;
}

.help-block  {
    font-size: .875rem;
    color: #0000008c;
    font-weight: 500;
    margin-top: .55rem;
    display: inline-block;
    text-align: right;
    width: 100%;
}
.show-for-sr {
    position: absolute !important;
    width: .0625rem;
    height: .0625rem;
    overflow: hidden;
    clip: rect(0,0,0,0);
}
.clickable:hover {
    cursor:pointer;
}
.list-item-title {
    color:$secondary-color;
    font-weight:500;
}
.bold {
    font-weight: 600;
}

// image content layour shift

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {  
  height: auto;
} 
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }  
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }  
}

// Card
.card-panel {
    flex: 1;
}

.card {
	border-radius: .625rem .625rem 0 0 !important;
    overflow: hidden;
    box-shadow: 0 .375rem 1.875rem rgba(0,0,0,0.25);
    margin: 1.25rem 0 0;
    background-color: white;
}
@media ( max-width: 64em ) {
    .card {
        box-shadow: none;
    }

}

.card-head {
    font-size: 1.25rem;
    background-color: $primary-color;
    color: white;
    padding: 1rem 0 1rem 1.875rem;
    font-weight: 500 !important;
}

.card .card-image img {
	max-height: 15.625rem;
	height: 15.625rem;
    object-fit: contain;
    border-radius: .625rem .625rem 0 0 !important;
}

h2.card-title {
    color: lighten($primary-color, 10%) !important;
    font-size: 1.5rem;
    font-weight: 600 !important;
    margin-top: 0;
    margin-bottom: .625rem !important;
}
.card .card-content p {
    margin: 1em 0 1em 0;
    line-height: 1.5em;
    font-size: 1.125em;
    font-weight: 400;
    color: black !important;
}

// Toast

.para-modal-message-container {
    width: 100%;
    height: 3rem;
    background:rgba(88,125,222,1);
    color:white;
    font-size: 1.125em !important;
    font-weight: 500 !important; 
    line-height: 1.0;
}

// Button
.btn {
    font-family: 'Roboto', sans-serif;
}
.btn, .btn-large {
    font-weight: 500;
}
.btn-large-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.btn-link {
    border-radius: 0;
    color: lighten($secondary-color, 0%);
    font-weight: normal;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
    background-color: transparent;
    color: #23527c;
    text-decoration: underline;
}

.btn-link[disabled]:hover, fieldset[disabled] .btn-link:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:focus {
    color: #777;
    text-decoration: none;
}
.btn-link-blue {
    font-size: 1rem;
    font-weight: 600;    
    color: #23527c;
    background-color: transparent;    
    border-radius: 0;
    border-color: transparent;
    box-shadow: none;   
}
.btn-link-blue:hover {
    text-decoration: underline;
}
.btn-gtm-rp {
    background-color: transparent;
    border-color: transparent;
}

.btn-alternate {
    background-color: #eee;
    color: #333;
}
  
.btn-alternate:hover, .btn-alternate:active {
    background-color:  scale-color(#eee, $lightness: -10%);
    color: #222;
}

.btn_outline {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: .0625rem solid transparent;
    padding: .75rem 2.1875rem;
    font-size: 1.25rem;
    border-radius: .3125rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn_gray {
    border: .0625rem solid rgba(13, 36, 69, 31%);
    font-weight: 500;
    color: #737373;
}

.btn_gray:hover {
    border: .0625rem solid rgba(200, 32, 39, 31%);
    color: #c82027;
}
.btn i.fas {
    color: inherit;
}

i.fas.fa-lock-alt, .mi-lock-outline {
    // color:gold;
    color: #FFE700;
    color: white;
    font-size: 1.5rem !important;
    padding-right:.75rem;
}

.btn_gray .fa-chevron-double-right:hover, .mi-chevron-left:hover {
    color: #c82027;
}

.btn_gray.left i {
    margin-right: .625rem;
    font-size: 1rem;
}

.btn_gray.right i {
    margin-left: .625rem;
    font-size: 1rem;
    color: #737373;
}

.btn-large i, .fa-chevron-double-right {
    color: white;
    margin-left: 1rem;
    font-size: 1rem;
}
.btn-cart {
    width: 100%;
}

.mi-chevron-right {
    color: white;
    margin-left: 1rem;
    font-size: 1.5rem !important;
}

.cart-buttons i.fa-chevron-double-left, .cart-buttons .mi-chevron-left, 
.cart-buttons i.fa-trash-o, .cart-buttons .mi-delete-forever {
    color: black;
    margin-right: 1rem;
    font-size: 1rem !important;
}

.btn-tagline {
    margin-top:.5rem;
    font-style:italic;
}

.anchor-button {
    text-align: center !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color:blue !important;
}
a.anchor-button:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.password-link {
    font-size:.8rem;
}
.password-link:hover {
    text-decoration: underline;
}

// Forms
.input-field input[type=text], .input-field input[type=email], .input-field input[type=tel], .input-field input[type=password] {
    background: transparent !important;
    font-size: 1.125rem;
    font-weight: 500;
    padding-left: .9375rem;
    border-bottom: .0625rem solid $secondary-color !important;
    box-shadow: none!important;
    height: 3.75rem;
    max-width: calc(100% - .9375rem);
    display: inline-block;
}
.input-field input[type=text].valid, .input-field input[type=email]:valid, .input-field input[type=tel]:valid, .input-field input[type=password]:valid, .input-field textarea:valid  {
    border-bottom: .0625rem solid #43a047 !important;
    box-shadow: 0 .0625rem 0 0 #43a047 !important;
}
.input-field label {
    padding-left: .75rem;
    font-size: 1.125rem;
    color: #737373;
    font-weight: 500;
}
.input-field input[type=text].valid + label, .input-field input[type=email]:valid + label, .input-field input[type=tel]:valid + label {
    color: #43a047;
}
.input-field input[type=text], .input-field input[type=text]:focus {
    border-bottom: 1px solid black !important;
    box-shadow: 0 1px 0 0 black !important;
    border-radius: 4px !important;
 }
.input-field textarea {
    background: #f3f4f6 !important;
    border: none !important;
    box-shadow: none!important;
    height: 5.625rem !important;
    border-radius: .3125rem !important;
    margin-bottom: 0;
    padding-left: 1.125rem;
    font-size: 1.125rem;
}
.input-field textarea:focus {
    margin-top: 0;
    border: none !important;
}


//Select

#shipping_fields, #bill-to-body, .product-block, .login-box {
    overflow: visible;
}

.select-dropdown {
    padding-left: .625rem !important;
    font-size: 1.125rem !important;
    width: calc(100% - .625rem);
    color: #737373;
    font-weight: 500;
}
.select-wrapper svg.caret {
    z-index: 5;
    width: 2rem;
    height: 2rem;
    margin-right: 1.25rem;
    fill: $secondary-color;
}
.input-field .select-dropdown {
    width: calc(100% - .625rem);
}

// input number 
.input-group {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: .625rem;
}

.qty-box {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: .625rem;
    max-width: 9.375rem;
}

.qty-box .input-group-btn {
    position: absolute;
    right: .625rem;
    top: 0;
    width: 1.25rem;
    height: 3.75rem;
}

.qty-box .input-group-btn .btn-number {
    width: 1.25rem;
    height: 1.25rem;
    background: #fff;
    border: .0625rem solid #aeb5c1;
    border-radius: .1875rem;
    line-height: .875rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
    position: absolute;
    top: .5rem;
}

.qty-box .input-group-btn .quantity-left-minus {
    top: 2.125rem;
}
.qty-box #quantity {
    background-color: #ffffff;
    height: 3.75rem;
    border: none;
    padding-left: 1.25rem;
    font-weight: 500;
    font-size: 1.375rem;
    border-radius: .3125rem;
    max-width: calc(100% - 1.25rem);
    margin-bottom: 0;
}
.product-quantity .qty-box {
    border: .0625rem solid #aeb5c1;
    border-radius: .3125rem;
}

.qty-box .input-group-btn .btn-number:hover,
.qty-box .input-group-btn .btn-number:focus {
    border-color: $secondary-color;
    color: $secondary-color;
}
.radio_box .prompt {
    font-size: 1.5rem;
    margin-bottom: 1.5625rem;
    margin-top: 0;
    font-weight: 500;
}
.radio_box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.radio_box label {
    font-size: 1.125rem;
    margin-right: .625rem;
    font-weight: 500;
}



// Global Header
.top_menu {
    padding: 1.1875rem 0;
    background-color: white;
}
.top_menu .row {
	margin-bottom: 0;
}
.top_menu .brand-logo {
	margin-top: .25rem;
}
.right_topmenu {
	display: flex;
}
.right_topmenu li {
	padding: 0 1.375rem 0 1.375rem;
}
.right_topmenu li a, .right_topmenu li button {
    font-family: 'Roboto', sans-serif;
	font-size: 1.125rem;
	color: #000;
	font-weight:500;
}
.right_topmenu li a:hover, .right_topmenu li button:hover {
	color: #c82027;
}
.right_topmenu li:last-child {
	padding-right: 0;
}
.dropdown-content{
	top: auto !important;
}
.top_menu .right_topmenu {
	margin: .5rem 0 0;
}
.top_menu .right_topmenu .material-icons {
    display: inherit;
    position: relative;
    line-height: initial;
    top: .375rem;
    color: #c82027;
    font-size: 1.5rem;
    margin-right: .375rem;
}
.top_menu .right_topmenu .material-icons.right {
	margin-right: 0;
    font-size: 1.25rem;
    top: .5625rem;
}
.top_menu .right_topmenu .dropdown-content {
	top: auto !important;
	min-width: 11.875rem;
}
.top_menu .right_topmenu .dropdown-content li>a {
    font-size: 1rem;
    color: black;
    display: block;
    line-height: 1.375rem;
    padding: .875rem 1rem;
}
.top_menu form.search_form {
    max-width: 31.25rem;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
}

.top_menu .input-field, .search_form .input-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.top_menu  form.search_form input[type="text"]:not(.browser-default),
nav  form.search_form input[type="text"]:not(.browser-default) {
	margin: 0;
    border: none;
    color: #fff;
    font-weight: 600;
    padding-left: 3rem;
    box-shadow: none!important;
    background: #0d2445 !important;
    border-radius: .375rem;
}
.top_menu  form.search_form .input-field input[type='text'] {
    border-bottom: .0625rem solid $primary-color !important;
    box-shadow: none!important;
}

.top_menu  form.search_form .material-icons, .fa-search { 
    color: #fff;
    line-height: 2.75rem;
    margin-left: .75rem;
    font-size: 2rem;
    width: auto;
}

.medium-and-down-show {
    display:none;
}

@media ( max-width: 48em ) {
    .medium-and-down-show {
        display: inline-block;
    }
    form.mobile_search_form {
        width: 100%;
        display: inline-block;
        margin-top: .25rem;

    }
    input.autocomplete[type="text"]:not(.browser-default) {
        margin: 0;
        height: 2.5rem !important;
        max-height: 2.5rem !important;
        border: none;
        color: #fff;
        font-weight: 600;
        padding-left: 3rem;
        box-shadow: none;
        background: #0d2445 !important;
        border-radius: .375rem;
    }
    i.material-icons.prefix { 
        color: white;
        line-height: 1.875rem;
        margin-left: .75rem;
        font-size: 2rem;
        width: auto;
    }
}
.top_menu input[type=text]:not(.browser-default):focus:not([readonly]) {
	border:none;
	box-shadow: none;
}
.top_menu .input-field .prefix ~ input {
    width: calc(100% - 3rem);
}
.top_menu .input-field{
    margin-top: 0;
}
.right_topmenu li.cart, .right_topmenu li.account_person {
    position: relative;
}
.right_topmenu li.cart:before, .right_topmenu li.account_person:before  {
	content: "|";
    font-size: 1.125rem;
    display: inline-block;
    left: -0.25rem;
    position: absolute;
    top: .25rem;
}

// Megamenu
nav {
	background-color: #c82027;
}
// nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
	// height: 3.125rem;
	// line-height: 3.125rem;
// }

nav ul a {
    font-family: 'Roboto', sans-serif;
	font-size: .8125rem;
	padding: 0 .25rem;
}


@media ( max-width: 64em ) {
    .wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 62rem !important;
        margin: 0 auto !important;
    }
    .right_topmenu li {
        padding: 0 .25rem 0 .25rem;
    }
    .right_topmenu li a {
        font-size: 1rem;
    }
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    .wrapper {
        width: 95%;
        margin: 0 auto;
    }
    .container {
        width: 100%;
        max-width: 79.9375rem;
        margin: 0 auto;
    }
    .right_topmenu li {
        padding: 0 .25rem 0 .25rem;
    }
    .right_topmenu li a {
        font-size: 1rem;
    }
    nav ul a {
        font-size: .9rem;
        padding: 0 .125rem;
    } 
}
@media (min-width: 80.0625em) and (max-width: 85.25em) {
    .wrapper {
        width: 90%;
        margin: 0 auto;
    }
    .container {
        width: 95%;
        max-width: 85.3125rem;
        margin: 0 auto;
    }
    .right_topmenu li {
        font-size: .85rem;
        padding: 0 1.25rem 0 1.25rem;
    }
    nav ul a {
        font-size: 1rem;
        padding: 0 .375rem;
    }
}
@media (min-width: 85.3125em) and (max-width: 89.9375em) {
    .wrapper {
        width: 90%;
        margin: 0 auto;
    }
    .container {
        width: 90%;
        max-width: 90rem;
        margin: 0 auto;
    }
    .right_topmenu li {
        font-size: .85rem;
        padding: 0 1.25rem 0 1.25rem;
    }
    nav ul a {
        font-size: 1.0625rem;
        padding: 0 .375rem;
    } 
}
@media (min-width: 90em) and (max-width: 95.9375em) {
    .wrapper {
        width: 85%;
        margin: 0 auto;
    }
    .container {
        width: 85%;
        max-width: 96rem;
        margin: 0 auto;
    }
    .right_topmenu li {
        padding: 0 1.25rem 0 1.25rem;
    }
    nav ul a {
        font-size: 1.125rem;
        padding: 0 .25rem;
    } 
}
@media (min-width: 96em) and (max-width: 105em) {
    .wrapper {
        width: 80%;
        margin: 0 auto;
    }
    .container {
        width: 80%;
        max-width: 105rem;
        margin: 0 auto;
    }
    nav ul a {
        font-size: 1.125rem;
        padding: 0 .25rem;
    } 
}
@media (min-width: 105.0625em) {
    .wrapper {
        width: 80%;
        margin: 0 auto;
    }
    .container {
        width: 80%;
        max-width: 105rem;
        margin: 0 auto;
    }
    nav ul a {
        font-size: 1.25rem;
        padding: 0 .875rem;
    } 
}

/*
nav i.right {
	margin-left: 0;
	font-size: 1.125rem;	
}
*/
.megamenu li img {
    position:relative;
    top: .375rem;
}
.mobile-nav i {
    height: 3.125rem !important;
	line-height: 3.125rem !important;
}

.megamenu .dropdown-content a {
    color: $primary-color !important;
    font-weight: 500;
}
.megamenu-content {
    width: 300px !important;
    box-shadow: 0 8px 8px rgba(0,0,0,0.25);
}
.megamenu-content ul li {
    float: none !important;
    text-align: left;
}
nav ul li {
    float: none !important;
    text-align: left;
}

// Breadcrumbs

.breadcrumb, .list_breadcrumb:last-child {
    color: darken($secondary-color, 0%) !important;
    font-size: 1.5rem;
    font-weight: 500;
}

ul.see-also li a:hover, ul.see-also li a:focus, .list_breadcrumb a:hover, .list_breadcrumb a:focus {
    color:#0066cc !important;
    text-decoration: underline;
    cursor: pointer;
}


.breadcrumb:before {
    // content: "\e5cc";
    // font-family: 'Material Icons';
    // color: $primary-color;
    // font-size: 1.5rem;
    content: "" !important;
    position:relative;
    top: .375rem;
    margin-left: 0.75rem;
    margin-right: 1rem;
}
img.breadcrumb {
    position:relative;
    top: .5rem;
}


.category_banner h2 {
    margin-top: .5rem !important;
}

// Table

.table-wrapper {
    background-color: #fff;
    border: .0625rem solid $primary-color;
    border-radius: .625rem;
    overflow-x: scroll;
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.table-wrapper .table-header {
    background-color: $secondary-color;
    color: white;
    margin:0;
    padding: 1.5rem 0 1.5rem 0;
    font-size: 2rem;
    font-weight: 500;
}
   
.table-wrapper::-webkit-scrollbar-track {
    background-color: lighten($primary-color, 10%);
}
   
.table-wrapper::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: darken($secondary-color, 0%);
    box-shadow: inset 0 0 .375rem rgba(0, 0, 0, 0.5);
}
.table-wrapper::-webkit-scrollbar {
    width: 1rem;
}

table {
    width: 100%;
    table-layout: auto;
    font-size: 1.5rem;
    font-weight: 400;
}

thead {
    background-color: $primary-color;
    color: white;
}

th {
    padding: 1rem 0 1rem 1.875rem;
    font-weight: 500;
    text-align: center;
}

td {
    padding: .75rem 0 .75rem 1.875rem;
    text-align: center;
}
.product-table, .bl-letter-table, .marquee-content-table {
    font-size: 1.75rem;
}
.product-table td:before, .bl-letter-table td:before, .basket-table td:before, .checkout-table td:before, .marquee-content-table td:before, .invoice-table td:before {
    float: none !important;
}
.product-table tbody tr td:nth-child(2), .bl-letter-table tbody tr td:nth-child(1), .basket-table tbody tr td:nth-child(2), .checkout-table tbody tr td:nth-child(1), .marquee-content-table tbody tr td:nth-child(1) {
    text-align: left;
}
.product-table tbody tr th:last-child, .bl-letter-table tbody tr th:last-child, .basket-table tbody tr th:last-child, .checkout-table tbody tr th:last-child, .marquee-content-table tbody tr th:last-child {
    padding-right: .5rem;
}

table.striped>tbody>tr:nth-child(odd) {
    background-color: #fff;
}

table.striped>tbody>tr:nth-child(even) {
    background-color: #f3f4f6;
}
td a {
    color: blue;
}
td strike {
    color: #737373;
}

table .round {
    background: $secondary-color;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 6.25rem;
    display: inline-block;
    text-align: center;
    line-height: 3.125rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;
}

table td.item div {
    display: block;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #737373;
}

.basket-total {
    color: black;
    background-color: #ffe066;
    padding: .25rem;
}

table td .update {
    text-align: center;
    font-size: 1rem;
    font-weight:500;
    margin-top: -1rem;
}
.update a:hover {
    text-decoration: underline;
    cursor: pointer;
}

// Invoice table
.invoice-table {
    font-size: 1.25rem;
    margin-bottom: 0 !important;
}
.invoice-table tbody tr td {
    border-right: .0625rem solid #757575;
    font-size: 1.125rem;
    font-weight: 400;
    color: #737373;
    text-align: center;
    height: 5rem;
}
.invoice-table tfoot tr:first-child  {
    border-top: .0625rem solid #757575;
}
.invoice-table tfoot tr:last-child  {
    border-bottom: .0625rem solid #757575;
}
.invoice-table tbody tr:nth-child(even) {
    background-color: #f3f4f6;
}
.invoice-table tfoot tr:nth-child(odd) {
    background-color: #f3f4f6;
}
.invoice-table thead tr th:first-child {
    text-align: left;
    padding-left: 2.5rem;
}
.invoice-table tbody tr td:first-child {
    text-align: left;
    padding-left: 2.5rem;
}
.invoice-table thead tr th {
    border-right: .0625rem solid #ffffffc7;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    padding: 1.375rem .3125rem;
    font-size: 1.25rem;
}
.invoice-table tbody tr td:last-child, tfoot tr td:last-child  {
    border-right: none;
}
.invoice-table thead tr th:last-child {
    border-right: none;
}
.invoice-table .bg_breen {
    background: #109d59;
    color: #fff !important;
}

@media screen and (max-width: 25.6875em) {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: left;
    }
    .qty-box {
        display:inline-block;
        text-align: right;        
    }    
    table td::before {
      content: attr(data-label);
      padding-right: 2em;
      font-weight: 500;
    }
}

@media ( max-width: 37.5em ) {

}

@media ( min-width: 37.5625em ) and ( max-width: 64em ) {
    .invoice-table thead tr th:nth-child(3) {
        width: 11.25rem;
    }
    .invoice-table thead tr th:nth-child(4) {
        width: 13.75rem;
    }
    .table-wrapper::-webkit-scrollbar {
        width: none;
    }
    .table-summary {
        font-size: .875rem;
    }
}

@media (min-width: 64.0625em) and (max-width: 80em) {

}

@media (min-width: 80.0625em) and (max-width: 96em) {

}

@media ( min-width: 96.0625em ) {

}



// header-banner-image

.home_banner, .category_banner, .product_banner {
    position: relative;
}

.home_banner h1, .category_banner h1 {
	font-size: 2.87rem;
	text-transform: uppercase;
    position: absolute;
    top: -1rem;
    left: 10rem;
    font-weight: 400;
}
.home_banner h1 strong {
	display: block;
}

.home_banner p {
    position: absolute;
    top: 10rem;
    max-width: 25rem;
    left: 10rem;
	margin-bottom: 0;
}
.shop-star {    
    position: absolute;
    top: 27rem;
    left: 10rem;
    max-width: 15.625rem;
}
.shop-star ul{
	display: flex;
	flex-wrap: wrap;
	margin-top: .375rem;
}
.shop-star ul li {       
    color: #fbbd00;
    margin-right: .375rem;
}
.shop-star ul li.review-count {
	font-size: 1.125rem;
	margin-left: .625rem; 
	color: #000000;
}
@media ( max-width: 37.5em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 1.5rem;
        top: -1.25rem;
        left: 1.25rem;
        max-width: 12rem;
    }
    .home_banner_text {
        font-weight: 400;
        margin-top: -1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-rendering: optimizeLegibility;
    }
}
@media ( min-width: 37.5625em ) and ( max-width: 64em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 5rem;
    }
    .home_banner p {
        top: 8rem; left: 5rem; font-size: 1.125rem; max-width: 20rem;
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 19rem;
        left: 44rem;
    }
}
@media (min-width: 64.0625em) and (max-width: 80em) {
    .home_banner h1, .category_banner h1 {
        font-size: 2.875rem;
        position: absolute;
        top: -.5rem;
        left: 5rem;
    }
    .home_banner p {
        top: 10rem; left: 5rem; font-size: 1.125rem; max-width: 24rem;        
    }
    .header {
        font-size: 2.875rem;
    }
    .shop-star {
        top: 26rem;
        left: 5rem;
    }
}
@media (min-width: 80.0625em) and (max-width: 96em) {
    .home_banner h1, .category_banner h1 {
        font-size: 3rem;
        position: absolute;
        top: 0;
        left: 5rem;
    }
    .home_banner p {
        top: 10rem; left:5rem; font-size: 1.25rem; max-width: 26rem;        
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 28rem;
        left: 5rem;
    }
}
@media ( min-width: 96.0625em ) {
    .home_banner h1, .category_banner h1 {
        font-size: 3rem;
        position: absolute;
        top: 0;
        left: 12rem;
    }
    .home_banner p {
        top: 10rem; left: 12rem; font-size: 1.25rem; max-width: 26rem;        
    }
    .header {
        font-size: 3rem;
    }
    .shop-star {
        top: 28rem;
        left: 12rem;
    }
}

// Category header image
.category_banner {
    min-height: 15.625rem;
}

.header-image {
    position: relative;
	margin-left: -.75rem;
}

.category_banner  h1 strong {
	display: block;
}

.category_banner p {
    position: absolute;
    top: 8rem;
    margin-left: 2rem;
	margin-bottom: 1rem;
}

.category_banner ul {
    position: absolute;
    top: 13rem;
    margin-left: 2rem;
	margin-bottom: 0;
}


// Materialize Alerts

.materialert {
    position: relative;
    min-width: 9.375rem;
    padding: 1.9375rem;
    margin-bottom: 1.25rem;
    margin-top: .9375rem;
    border: .0625rem solid transparent;
    border-radius: .25rem;
    transition: all 0.1s linear;
    box-shadow: 0 .125rem .125rem 0 rgba(0,0,0,0.14), 0 .1875rem .0625rem -2px rgba(0,0,0,0.12), 0 .0625rem .3125rem 0 rgba(0,0,0,0.2);
    align-items: center;
}
.materialert .material-icons {
    margin-right: .625rem;
}
.materialert .close-alert {
    border: 0;
    cursor: pointer;
    color: inherit;
    background: 0 0;
    font-size: 1.375rem;
    line-height: 1;
    font-weight: bold;
    // text-shadow: 0 1px 0 rgba(255, 255, 255, .7);
    filter: alpha(opacity=40);
    margin-bottom: -.3125rem;
    position: absolute;
    top: 1rem;
    right: .3125rem;
}
.materialert.info {
    // background-color: #039be5;
    // background-color: #f3f4f6;
    background-color: lighten($primary-color, 75%);    
    color: $primary-color;;
}
.materialert.success {
    background-color: #43a047;
    color: #fff;
}
.materialert.error {
    background-color: #c62828;
    color: #fff;
}
.materialert.danger {
    background-color: #c62828;
    color: #fff;
}
.materialert.warning {
    background-color: #fbc02d;
    color: black;
}

// Acordian

.collapsible {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important; box-shadow: none !important;
}

.collapsible li {
    margin-bottom: 1.875rem;
    background-color: #fff;
    border-radius: .5rem;
}
.collapsible .collapsible-header {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
    box-shadow: none !important;
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary-color;
    padding-right: 3.75rem;

}
.collapsible .active p {
    margin-top: 0;
}
.collapsible .collapsible-body { 
    padding-left: 3.75rem;
    padding-top: 0;
    border-bottom: none;
}
.collapsible .collapsible-body p:last-child {
    margin-bottom: 0;
}
.collapsible .red_icon { 
    color: $secondary-color;
    font-size: 1.125rem;
    margin-top: .43;
}
.collapsible .collapsible-header {
    position: relative;
}
.collapsible .collapsible-header:after { 
    position: absolute;
    content: "+";
    font-size: 2.5rem;
    right: 1.25rem;
    top: .125rem;
    font-weight: 400;
    color: $primary-color;
}
 .collapsible .active .collapsible-header:after { 
    position: absolute;
    content: "-";
}

// List box
.list-box {
    margin-bottom: 3.75rem;
}
.list-box ul {
    border: .0625rem solid #000033;
    border-radius: .5rem;
}
.list-box li:first-child {
    color: #fff;
    background: $primary-color;
    font-size: 1.5rem;
    padding: 1.375rem 0 1.375rem 2.5rem;
}
.list-box li {
    border-bottom: .0625rem solid #000033;
    background-color: #fff;
    color: #000000;
    padding: 1.375rem 0 1.375rem 2.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    display: block;
}
.list-box li a {
    color: #000000;
    padding: 1.375rem 0 1.375rem 2.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    display: block;
}
.list-box li a:hover {
    color: $secondary-color;
}
.list-box li:last-child, .list-box li:first-child{
    border-bottom: none;
}
.list-box li:first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.list-box li:last-child {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.list-box li i {
    padding-right: 2rem;
    font-size: 2.5rem;
    color: $secondary-color;
}

// Global footer

.why_buy_section {
    margin-top: 1.25rem;
    background-color:white;
    padding-bottom: .75rem;
}
.flex-item-why-buy {
    display: flex !important;
    flex: 1 !important;
    flex-basis: 50%;
    min-width: 19rem;
}
.why-buy-card {
    padding: 1rem;
    border-radius: .625rem .625rem .625rem .625rem !important;
    overflow: hidden;
    box-shadow: 0 .3125rem 1.875rem rgba(0,0,0,0.25);
    margin: 1.25rem 0 1.25rem 0;
    background-color: white;
}

.whyshop_section {
    padding-bottom: 3rem;
}
.whyshop_section .card-panel {
    min-height: 17.3125rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0,0,0,0.05);
    border-radius: .625rem;
    padding-top: 2.875rem;
}
.whyshop_section .card-panel .img {
    height: 8.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyshop_section .card-panel h4 {
    font-size: 1.75rem;
    font-weight: 500;
    margin-top: 1.625rem;
}
.whyshop_section .row {
    padding: 0 1rem;
}
.payment_option {
    margin-top: 6rem;
}
ul.payment_option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 98%;
    // margin: 100px auto 0;
}
ul.payment_option li {
    flex:0 0 100%;
    max-width: 16.66%;
    text-align: center;
}
ul.payment_option li:first-child{
    text-align: left;
    max-width: 13%;
}
ul.payment_option li:nth-child(5){
    text-align: right;
    max-width: 18%;    
}
ul.payment_option li:last-child{
    text-align: right;
    max-width: 19%;    
}

footer.page-footer {
    position: relative;
	background: url(../graphics/00000002/footer_bg.jpg) top center no-repeat;
	background-size: cover;
	padding-top: 4.75rem;
	padding-bottom: 1.25rem;
}
footer.page-footer .top_footer .footer-logo {
    margin-top: 1rem;
}
footer.page-footer .top_footer .footer-logo img {
    padding-bottom: 2rem;
}
footer.page-footer .top_footer {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
footer.page-footer h5 {
	color: #C82027;
	font-size: 1.375rem;
	margin-bottom: 1.375rem;
}
footer.page-footer li a {
	color: #fff;
	font-size: 1.125rem;
	line-height: 2.5rem;
}
footer.page-footer li a:hover {
	color: #C82027;
}
footer.page-footer .mid_footer .row {
    padding: 2.5rem 0 1.4375rem;
    margin: 1.25rem 0 1.25rem !important;
}
footer.page-footer .mid_footer .row {
    margin-bottom: 0;
    border-top: .125rem solid rgba(200,32,39,.4);
    border-bottom: .125rem solid rgba(200,32,39,.4);
}
footer.page-footer .footer-copyright p {
    font-size: .9375rem;
}

// Image wrapper
.image-wrap-container img {
    margin: 1rem;
}
.image-wrap-container h2 {
    padding-top: 1.5rem !important;
}
.image-wrap-container p {
    font-size: 1.125rem;
}
#secproddescrip img {
    padding: 0 1rem 0 0;
}

@media only screen and (max-width: 48em), (max-width: 37.5em) {
    .image-wrap-container img {
        padding-bottom: 2rem;
    }
}

// Homepage

.aboutusbanner {
    position: relative;
    margin-top:1.25rem;
}

.aboutusbanner h2 {
	font-size: 2.87rem;
	text-transform: uppercase;
    position: absolute;
    top: 0;
    margin-left: 1.5rem;
    font-weight: 400;
}

.aboutusbanner p {
    margin-left: 1.5rem;
    margin-bottom: 0;
    color: #000000;
    position: absolute;
    top: 9.5rem;
    max-width: 30rem;
}

@media ( max-width: 62em ) {
    .aboutusbanner h2 {
        top: -.5rem;
        font-size: 2rem;
    }
    .aboutusbanner p  {
        top: 6rem;
        font-size: 1rem;
        max-width: 20rem;
    }
}

@media ( max-width: 37.5em ) {
    .aboutusbanner h2 {
        font-size: 1.75rem;
        position: absolute;
        top: -1.5rem;
        max-width: 20rem;
    }
    .aboutusbanner h2 strong {
        display: block;
    }
    .aboutusbanner_text {
        background-color: white;
        padding: .6rem;
        margin-left: .8rem;
        margin-right: .8rem;
        font-weight: 400;
        margin-top: -1rem;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-rendering: optimizeLegibility;
    }
}

.section_testimonial {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}
.flex-testimonial {
    display: flex !important;
    flex: 1 !important;
    flex-basis: 32%;
    min-width: 19rem;
    margin: .5rem; 
}
.testimonial-quote {
    // display: inline-block;
    position: absolute;
    float:left;
    font-size: 3.125rem;
    color: #c62026;
    width: 5.375rem;
    height: 3.9375rem;
    top: 1.125rem;
    left: 0;
}
.testimonial_person {
    display: block;
    width: 100%;
    position: relative;
}
.testimonial_person img {
    width: 6.25rem;
    height: 6.25rem;
    max-width: 6.25rem;
    border: .25rem solid #fff;
    box-shadow: 0 0 1.875rem rgba(0, 0, 0, .1);
}
.testimonial_person img {
    margin-left: 2.5rem;
    object-fit: cover;
}
.testimonial_headline {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: .25rem;
    margin-top: 1.25rem;
}

// Category page

ul.see-also li {
    color: darken($primary-color, 0%);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-right: .5rem;
    display: inline-block;
}

ul.see-also li a {
    color: darken($primary-color, 0%);
}

ul.see-also li:first-child  {
    color: darken($primary-color, 0%);
    font-weight: 700;
}

/*
.fa-grip-lines-vertical {
    color: lighten($primary-color, 10%);
    color: black;
    margin-left: .5rem;
}


.material-icons-drag-handle {
    color: black;
    margin-left: .5rem;
	transform: translateY(.5rem) rotate(90deg);
}
*/

@media ( max-width: 62em ) {
    .category_banner h1 {
        font-size: 1.75rem;
        text-transform: uppercase;
        position: absolute;
        top: -.75rem;
        margin-left: 2rem;
    }
    .category_banner p {
        top: 5rem; font-size: 1rem;
    }
    .category_banner ul {
        position: absolute;
        top: 10rem;
        margin-left: 2rem;
        margin-bottom: 1rem;
    }
    .category_banner ul li {
        font-size: .75rem;
        margin-right: .25rem;
    }
    .fa-grip-lines-vertical {
        font-size: 0.75rem;
    }
    .category-header {
        margin-top: 3rem;
    }
}

@media ( max-width: 37.5em ) {
    .category_banner h1 {
        font-size: 1.5rem;
        position: absolute;
        top: -1rem;
        max-width: 24rem;
    }
    .category-header {
        margin-top: .25rem;
    }
}

// Cart

.cart-header-button {
    margin-top: 2rem;
}

.free-shipping-banner {
    background-color: white;
}

.free-shipping-banner h2 {
	font-size: 2.87rem;
	text-transform: uppercase;
    font-weight: 500;
}

.free-shipping-banner p {
    font-size: 2rem;
    font-weight: 400;
	margin-bottom: 0;
}

@media ( max-width: 62em ) {
    .free-shipping-banner h2 {
        margin-top: 0;
        font-size: 2.87rem;
        text-transform: uppercase;
        font-weight: 500;
    }

    .free-shipping-banner p {
        margin-top: -.5rem;
        line-height: 2rem;
    }
    .cart-header-button h2 {
        margin-top: 1rem;
        font-size: 3.25rem;
    }
    table thead th {
        padding: 1.5rem;
    }
}

@media ( max-width: 37.5em ) {
    .cart-header-button h2 {
        margin-top: .25rem;
        font-size: 3rem;
    }
}

// Checkout
.checkout-steps {
    margin: 2rem 0 1rem 0;
}
.costep {
    border:.125rem solid white;
    border-radius: .375rem;
    padding: .75rem !important;
    color:lighten($primary-color, 0%);
    background-color:lighten($primary-color, 75%);
    font-family: 'roboto', sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
}
.costep span {
    margin-left: 1rem;
    font-size: 1.75rem;
    line-height: 1.75rem;
}
.fas.fa-check, .mi-check {
    margin-right: 1rem;
    font-size: 1.75rem !important;
    font-weight: 600;
    line-height: 1.75rem;
}
.coactive {
    background-color: #bdbdbd;
    color: black;
}
.codisabled {
    background-color: #bdbdbd;
    // background-color:lighten($primary-color, 65%);
    color: white;
    font-weight: 400;
}
.check-input {
    font-size: 1.125rem;
}
.fas.fa-check-circle, .mi-check-circle {
    margin-right: 1rem;
    font-size: 1.375rem !important;
    font-weight: 600;
    line-height: 1.75rem;
    color: $secondary-color;
}
.checkout-key {
    display: inline-block;
}
.checkout-key  span {
    font-size: 1.5;
    font-weight: 600;
}
.checkout-key span.key-line {
    margin: 0 1rem;
    color: red;
}
.billing-controls {
    margin-left: .5rem;
}

// Product Page

.side .btn {
    width: 85% !important;
    margin: 0 auto;
    margin-top: 1.875rem;
}

.items th {
    display: revert;
}

.next {
    font-size: 1.125rem;
    color: #0000008c;
    font-weight: 500;
    text-align: center;
    margin-top: 1.25rem;
    display: inline-block;
    text-align: center;
    width: 100%;
}

// Forms

// Checkout

.checkout_form {
    padding: .625rem 1.875rem !important;
}

.heading {
    text-align: center;
    display: block;
    width: 100%;
    padding: 1.125rem 0;
    background: #0d2444;
    color: #fff;
    margin-bottom: 0;
    border-radius: .625rem .625rem 0 0;
}

.contact-us .card.card_left {
    flex-wrap: wrap;
}

.shop {
    width: 100%;
    background: #0d2444;
    border-radius: .625rem .625rem 0 0;
}

.shop h2 {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 0;
    color: #ffffff;
    margin: 0 !important;
    padding: 1.5625rem 1.875rem;
}

.continue {
    width: .9375rem;
    position: relative;
    top: .125rem;
}

// Reviews

.star_review_box {
    margin: 0 auto;
}
ul.star_rating {
    display: inline-block;
    margin-top: .25rem;
    margin-bottom: .5rem;
}

ul.star_rating li {
    display: inline-block;
    // margin: 0 .25rem;
    margin: 0;
    color: #ffcc00;
}
ul.star_rating li i {
    font-size: 1.25rem;
    color: #ff9c00;
}
.star_review_box span.badge {
    display: inline-block;
    font-size: 1.125rem;
    color: #fff;
    float: none;
    margin-left: .625rem;
    margin-right: 1.75rem;
    line-height: 1.875rem;
    height: 1.875rem;
    padding: 0 .75rem;
    border-radius: .3125rem;
}

.star_review_box .read_reviews {
    display: inline-block;
    font-size: 1.125rem;
    color:blue;
    margin: 0 0 .5rem 0;
}

// Product summary review
.product-aggregate-review {
    margin-top: 2rem;

}
.review-callout {
    width: 100%;
    min-height: 17.4rem;
    text-align: center;
    border-radius: .625rem;
    overflow: hidden;
    margin-bottom: 1rem;
}
.review-callout.green {
    background-color: #32cd32 !important;
}
.review-callout.green .rating-number {
    font-size: 5rem;
    font-weight: 600;
    color: #fff;
    margin-top: 1.5rem;
    display: inline-block;
    margin-bottom: .625rem;
}
.review-callout ul.star_rating {
    display: flex;
    margin-top: .5rem;
    margin-bottom: .8125rem;
    align-items: center;
    justify-content: center;
}
.review-callout ul.star_rating li i {
    margin-right: .625rem;
    color: #ffcc00;
}
.review-callout .black {
    height: 3.75rem;
    margin-top: 2rem;
    display: block;
    width: 100%;
}
.review-callout .black .rating-scale {
    margin: 0;
    line-height: 3.75rem;
    color: #fff;
    font-weight:600;
}
.review-callout.gray {
    background-color: #f3f4f6;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-flow: column;
    padding: 0 .9375rem;
    border: .0625rem solid #b6bdc7;
}
.review-callout.gray .review-count {
    width: 100%;
    display: block;
    font-size: 2.1875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

// Product detail review
.product-review-container {
    padding: 3rem;
    display: inline-block;
    border-bottom: .0625rem solid #b6bdc7;
    width: 100%;
}
.product-review-container:first-child {
    border-top: .0625rem solid #b6bdc7;
}
.product-review-container .header_review_box {
    margin-bottom: 1.25rem;
    position: relative;
}
.verified_btn {
    background-color: #f3f4f6;
    padding: .5rem 1rem;
    max-width: 18.75rem;
    width: 100%;
    font-size: 1.5rem;
    color: #000;
    display: flex;
    float: right;
    margin-right: .9375rem;
    text-align: center;
    justify-content: center;
    line-height: 2.8125rem;
    border-radius: .3125rem;
}
.verified_btn span {
    width: 2.5625rem;
    height: 2.5625rem;
    display: inline-block;
    margin-right: .9375rem;
}
.product-review-content {
    margin-bottom: 1.875rem;
}
.product-review-content .review-headline {
    font-size: 1.5rem;
    font-weight: 600;
}
.product-review-content .review-author {
    font-weight: 500;
    color: #737373;
    font-size: 1.125rem;
}
.product-review-container p {
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 2rem;
}
.product-review-container:last-child {
    padding: 1.875rem 3.125rem 0 3.125rem;
    border-bottom:none !important;
}

// Product Page
.attribute-item-container {
    margin-top: .75rem;
}
.swatch-container {
    position:relative;
}
.swatches ul {
    list-style: none;
}
.swatches li {
    display: block;
    float: left;
    margin: 0 .125rem .375rem 0;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    cursor: pointer;
}
.swatches li img {
    width: 2.5rem;
    height: 2.5rem;
}
.swatch_preview {
    position: absolute;
    top: -144px;
    width: 120px;
    height: 140px;
    z-index: 100;
    border: 1px solid gray;
    text-decoration: none;
    text-align: center;
    font-size: .75em;
    background: #fff;
}
.product-block, .product-support {
    padding: 1.5rem;
    border-radius: .625rem .625rem 0 0!important;
    overflow: hidden;
    // box-shadow: 0 0.375rem 1.875rem rgba(0, 0, 0, 25%);
    box-shadow: 0 .5rem .5rem #9e9e9e;
    background-color: #fff;
}
.designer-anchor-button {
    text-align: left;
    font-size: 1em;
    font-weight: 500;
    color: #0d2445;
    margin-top: 1em;
}
.input-cluster-label input[type=text], .input-cluster-label input[type=text]:focus {
    background: 0 0!important;
    font-size: 1.125rem;
    font-weight: 500;
    padding-left: 8px;
    border: 1px solid black !important;
    border-radius: 0 8px 8px 0 !important;
    box-shadow: 0 0 0 0 black !important;
    height: 3.75rem;
    max-width: calc(100% - .9375rem);
    display: inline-block;
    margin-left: -12px !important;
}

#chrcnt {
    background-color: #bdbdbd;
    font-size: 1.125rem;
    font-weight: 500;
    padding: 8px;
    height: 3.75rem;
    display: inline-block;
    border-radius: 8px 0 0 8px !important;
    margin-top: -8px;
}
.related-navigation {
    margin-top: 2rem;
}
.header_product_detail {
    margin-top: 0;
}
.prod-form-holder {
    // margin-top: .5rem;
    margin-bottom: 1.875rem;
}
.product_gallery {
    max-width: 100%;
    padding-top: .9375rem;
    width: 100%;
    flex: 0 0 100%;
}
.product_gallery {
    max-width: 21.875rem;
    padding-top: .9375rem;
    width: 21.875rem;
}
.main-img-container {
    position: relative;
    // width: 320px;     
    // height: 320px;
    padding-top: .5rem;
    margin-bottom: 1.25rem;
    // overflow: hidden;
    object-fit: contain;
}
.main-img-container img {
    max-width: 100%;
    width: auto;
}
.thumbnails {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}
.thumbnails img {
    width: 3.5rem;
    height: 3.5rem;
    border: none;
    padding: 0;
    border-radius: .625rem;
    cursor: pointer;
    margin: 0 .375rem;
}
.large-link {
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 1.5625rem;
    text-decoration: none;
    display: inline-block;
    color: #757575;
    text-transform: uppercase;
}
.large-link:hover {
    text-decoration: underline;
    color: teal;
}
.action-block {
    flex: 1;
}
.action-block .help-block {
    display: block;
    font-size: 1rem;
    margin-top: .3125rem;
    margin-bottom: .625rem;
    color: #757575;
}
.product-code {
    font-size: 1.25rem;
    margin-bottom: 1.5625;
    margin-top: 0;
    font-weight: 600;
    color: #737373;
}
.product-code span {
    text-transform: uppercase;
    color: #c82027;
}
.product-color {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.product-specs {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2rem;
    color: #737373;
}
.product-specs strong {
    font-weight: 600;
    color: #737373;
}
.product-color .check {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: .3125rem;
    cursor: pointer;
    border: .0625rem solid #fff;
}
.select-left_box {
    position: relative;
    border-top: .0625rem solid #ccc;
    padding-top: 3.125rem;
    margin-top: 1.875rem;
    border-bottom: .0625rem solid #ccc;
    padding-bottom: 1.25rem;
    margin-bottom: 3.125rem;
}
.attr-prompt, label.attr-prompt {
    font-size: 1.375rem;
    font-weight: 600;
    color: #424242;
}
.checkbox {
    margin-bottom: 1em;
}
.checkbox label {
    font-size: 1.25rem;
    font-weight: 500;
    color: #424242;
}
.checkbox-qty-input {
    float: left;
    width: 4em;
}
.checkbox-qty-input span {
    display: block;
}
.days-to-ship {
    margin-top: 2rem;
}

.product-specs {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2rem;
    color: #737373;
}

.product-specs strong {
    font-weight: 600;
    color: #737373;
}
.product-support, .checkout-table {
    margin-left: .625rem;
}
@media only screen and (max-width: 62em) {
    .product-support, .checkout-table {
        margin-left: 0;
    }
}

@media only screen and (max-width: 37.5em) {
    .product-support {
        margin-left: 0;
    }
}
.prod-feature-header {
    font-size: 1.64rem;
    line-height: 110%;
    font-weight: 600;
    margin-top: 0;
    color: black;
}
.prod-feature-item {
    margin-top: 0;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #737373;
    display: block;
    width: 100%;
}
.schema-availability {
    margin-top: 1rem;
    font-size: 1.375rem;
    font-weight: 600;
    color: #c62026;
}
.product-price {
    font-size: 2.5rem;
    font-weight: 600;
    color: #c82027;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
}
.prodaddbut {
    margin-bottom: 1rem;
}
.prodaddbut button {
    width: 100%;
    display: block;
    max-width: 100%;
    color: white;
    font-size: 1.5rem;
}
.prodaddbut i.fas.fa-shopping-cart, .mi-shopping-cart { 
    color: white;
    margin-right: .75rem;
    font-size: 1.5rem !important;   
}
.qty-head {
    font-size: 1.5rem;
    font-weight: 500;
    color: #737373; 
}
.volume-price-body {
    margin-top: 1.875rem;
    border: .0625rem solid #dee2e6;
    border-radius: .625rem;
    overflow: hidden;
    margin-bottom: 2.1875rem;
}
.volume-price-header {
    background-color: #0d2445;
}

.volume-price {
    font-size: 90%;
    margin: .75rem 0;
}
.volume-price-header {
    padding:0;
    border:none;
}
.volume-price-header .volume-price-qty,.volume-price-header .volume-price-price {
    padding: 1rem 0 1rem 0rem;
    font-size: 1.5rem;
    font-weight:500;
    color:white;
}
.volume-price-row {
    height:auto;
    border-top:.0625rem solid #cacaca;
}
.volume-price-row:hover {
    font-weight:700;
    letter-spacing:-.0625rem;
}
.volume-price-qty, .volume-price-price {
    padding: 1rem !important;
    font-size: 1.5rem;
}

.volume-price-footer {
    padding:.375em .675em;
    font-weight:700;
    color:#757575;
    height:auto;
    border-top:.0625rem solid #cacaca;
}
.volume-price-row:nth-child(odd) {
    background-color: #f3f4f6;
}

.volume-price-row:nth-child(even) {
    background-color: #fff;
}
.volume-price-hide {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease;
    border-top:white solid .125rem;
}
.tabpanel {
    margin-top: 2rem;
}
.tabpanel .tab {
    border: .0625rem solid #ccc;
    background-color: #fff;
    padding: 0 1.875rem;
    line-height: 3.75rem;
    height: 3.75rem;
}
.tabpanel .tab a {
    font-size: 1.375rem;
    color: #000000;
    padding: 0 0;
    border-radius: .3125rem;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;
}
.tabpanel .tab a.active {
    border-color: #737373;
    color: #c62026;
    border: 1px solid red;
}
.tabpanel .tab i{
    color: #c82027;
    font-size: 2rem;
    padding: 0 1rem 0 1rem;
}

.tabpanel .tab a span {
    display: inline-block;
    position: relative;
    margin-right: 1.25rem;
}

ul.color-tab {
    display: flex;
    flex-wrap: wrap;
}
ul.color-tab li {
    margin-right: 1.875rem;
    text-align: center;
    margin-bottom: 1.875rem;
}
ul.color-tab li img {
    border-radius: .3125rem;
    width: 10.625rem;
    height: 10.375rem;
}
ul.color-tab li h6 {
    font-weight: 500;
    margin-top: .625rem;
    color: #737373;
}
.list-check {
    font-size: 1.125rem;
    line-height: 2rem;
    margin-bottom: 1.875rem;
}
.list-check li {
    padding-left: 1.875rem;
    position: relative;
    color: #737373;
    line-height: 2rem;
}
/*
.list-check li:before {
    position: absolute;
    content: "check_circle";
    font-size: 1.375rem;
    color: #c62026;
    font-family: 'Material Icons';
    left: 0;
}
*/
.red_link {
    font-size: 1.25rem;
    color: #c62026;
    font-weight: 500;
    cursor: pointer;
}
.red_link span {
    font-size: 1.5rem;
    margin-right: .9375rem;
}
#secprodreview {
    margin-bottom: 2rem;
}
#secprodreturn, .secprodkit, .sa_review_container, .download-box {
    width: 100%;
}
.customcolors li {
    margin: 1em;
    float: left;
    border: 1px solid #ddd;
    text-align: center;
}
.customcolors ul {
    display: inline-block;
}
.customcolors ul li img {
    padding: 0 !important;
}

//Modal

@media only screen and (max-width: 992px) {
    .modal  {
        max-width: 100% !important;
        width: 100% !important;
        max-height: 100% !important;
        height: 100% !important;
        top: 0 !important;
    }
}

#cu {
    height: 100%;
    min-height: 100%;
}



//About Page
.innerpage_banner_img {
    position: absolute;
    top: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}
.about-profile-container {
    margin: 2rem 0 2rem 0;
}
.about-profile-label {
    margin-right: .5rem;
    font-weight: 500;
    color: $secondary-color;
}
.about-profile {
    border: .0625rem solid #757575;
    padding: 1.25rem .625rem .625rem .625rem;
    background-color: white;
    font-size: 1.125rem;
    margin: 0;
    font-weight: 400;
    line-height: 1.5rem;
}

@media only screen and (max-width: 411px) {
    .about {
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 37.5em) {
    .customers {
        box-sizing: border-box;
        padding: 0 .75rem;
    }
}
.customers ul {
   display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -7px;
    align-items: center;
}
.customers ul li {
    background: #fff;
    width: 100%;
    max-width: 8.5rem;
    margin: 0 .5rem .9375rem;
    box-shadow: 0 0 20px 0 #0000001c;
    text-align: center;
    padding: 1.25rem .625rem .9375rem;
    border-radius: .3125rem;
    align-items: center;
    justify-content: center;
    height: 6.25rem;
    display: flex;
}

// Upload

.upload_section {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.upload_section .upload_input {
    background: #fff;
    height: 26.3125rem;
    display: block;
    border: .1875rem dashed #0D2445;
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem;
    background:#fff url(../graphics/00000002/files_icon.png) center center no-repeat;
}
i.fas.fa-plus, .mi-file-upload {
    background-color:#f3f4f6;
    color: $primary-color;
    font-size: 5rem !important;
    font-weight: 500;
    padding: 1rem 1.5rem;
    border: .1875rem solid $primary-color;
    border-radius: 4rem;
}

.upload_section .plus {
    width: 6.25rem;
    height: 6.25rem;
}
.upload_section .upload_input .file_label {
    display: block;
    width: 100%;
    padding-top: 7.75rem;
    flex-wrap: wrap;
    text-align: center;
    height: 26rem;
    cursor: pointer;
    position: relative;
    top: -1.375rem;
}
.upload_section .upload_input .file_label .upload-label {
    display: block;
    width: 100%;
    color: #000;
    color: $primary-color;
    flex-wrap: wrap;
    font-size: 1.875rem;
}
.upload_section [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}

.upload_section .contact_form {
    background-color: white;
    padding:2rem;
    border-radius: 0 0 .375rem .375rem;
}

// Blogs

.quote {
    padding: 3rem;
    font-size: 2rem !important;
    line-height: 2.5rem;
    font-weight: 400;
    text-transform : uppercase;
}
.quote strong {
    font-weight: 700;
    display: block;
}

.recent-blogs {
    display: inline-block;
    padding-bottom: 1rem !important;
    border: 1px solid red;

}

// Thank you page

.thank-you-header h1 {
    font-size: 2.25rem;
    line-height: 3.125rem;
    color: $primary-color;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0 !important;
}
.thank-you-header h2 {
    font-size: 2.25rem;
    line-height: 3.125rem;
    color: $secondary-color;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: .25rem !important;
    margin-bottom: 0 !important;
}
.thank-you-header p {
    font-weight: 600;
    color: #757575;
}
.order_confirmation h2 {
    margin-top: 3.75rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
}
.order_confirmation p {
    font-weight: 400;
    margin-bottom: 1.875rem;
}
.order_confirmation p strong {
    font-weight: 600;
    color: #000;
}
.order_confirmation p strong i {
    color: $secondary-color;
    margin-right: .5rem;
}
.invoice-container .card {
    padding-left: .9375rem;
    padding-right: .9375rem;
    box-shadow: none;
    border-top-left-radius: .625rem;
    border-top-right-radius: .625rem;
    margin-bottom: 1.875rem;
}
.invoice-container ul {
    margin: 1.875rem 0 .625rem 0;
    padding: 0 .625rem;
}
.invoice-container li {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 2.5rem;
}
.invoice-container ul li i {
    color: $secondary-color;
    margin-right: .5rem;
}
.address-container {
    border: .0625rem solid rgba(37, 57, 87, 31%);
    border-radius: .5rem;
    margin-bottom: 1.875rem;
}

.address-header {
    margin: 0;
    padding: 1.25rem 0 1.25rem 1.625rem;
    color: #fff;
    background: $secondary-color;
    font-size: 1.5rem;
    font-weight: 400;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.address-container ul {
    padding: 1.875rem 0 2.5rem 1.625rem;
    margin: 0;
    font-size: 1.125rem;
    line-height: 2.1875rem;
    font-weight: 400;
}
.address-container ul li {
    font-weight: 400;
}

// Banner Page

.category-header-form-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: white;
    padding: 3rem 1rem 2rem 1rem;
}

.category-header-form {
    margin-left: 1rem;
}

 // Vinyl Lettering

 .usp_container {
    margin-top: 2em !important;
    // display: -webkit-inline-box;
    width: 100%;
}

.usp_container .card-panel {
    min-height: 22.5rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0, 0, 0, 0.05);
    border-radius: .5rem;
    padding-top: 2.875rem;
    padding: 1.875rem 1.875rem .625rem;   
}

.usp_container .card-panel .img {
    width: 6.875rem;
    height: 6.875rem;
    background: #F3F4F6;
    color: lighten($primary-color, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 9.375rem;
}
.usp_container .card-panel .usp-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: .75rem;
    padding: 0 1.25rem 0;
}
.usp_container .card-panel p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.faqtitle {
    margin-top: 1em;
    font-weight: 400;
    color: lighten($primary-color, 10%);
    font-size: 2em;
    line-height: 1.125em;
    text-transform: uppercase;
}
.faqtitle strong {
    font-weight: 700;
}
.image-wrap-body a {
    margin-top: 1rem;
}

.viny_section_two {
    background-color: $primary-color;
    height: 31.25rem;
    margin-top: 10rem;
    margin-bottom: 12.5rem;
}
.viny_section_two ul {
    display: flex;
    margin-top: -154px;
    flex-wrap: wrap;
    width: 616px;

}
.viny_section_two ul li {
    width: 50%;
    padding: 0 .9375rem;    
}
.viny_section_two ul li:nth-child(1) {
    margin-top: 3.125rem;
}
.viny_section_two ul li:nth-child(4) {
    margin-top: -3.125rem;
}
.viny_section_two .card-panel {
    min-height: 17.375rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0, 0, 0, 0.05);
    border-radius: .625rem;
    padding-top: 2.875rem;
    padding: 1.9375rem .9375rem .9375rem; 
    margin: .9375rem 0;   
}
.viny_section_two .img {
    width: 9.375rem;
    height: 9.375rem;
    background: #F3F4F6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 9.375rem;
}
.viny_section_two .card-panel h5 {
    font-size: 1.4375rem;
    font-weight: 400;
    margin-top: 1.0625rem;
    margin-bottom: .3125rem;
    padding: 0 1.25rem 0;
}
.viny_section_two .card-panel p {
    margin-top: 0;
    margin-bottom: 1.25rem;
} 

.viny_section_two h2 {
    color: #fff;
    margin-top: 5.75rem;
    margin-left: 5rem;
}
.viny_section_two .car {
    margin-top: 1rem;
    display: inline-block;
    width: 65rem;
}
@media ( max-width: 48em ) {
    .viny_box {
        margin-left: 4rem;
    }
    .viny_section_two {
        height: auto;
        margin-top: 1rem;
        margin-left: auto;
        left: auto;
        right: auto;
        margin-bottom: 10rem;
    }
    .viny_section_two .car_img {
        margin-left: 0;
        width: 52rem;
        margin-bottom: -100px;
    }
    .viny_section_two ul {
        margin-top: 1rem;
    }
    .viny_section_two ul li:nth-child(2) {
        margin-bottom: 0;
    }
    .viny_section_two ul li:nth-child(3) {
        margin-top: 0;
    }
}
@media(max-width: 411px) {
    .viny_section_two h2 {
        margin-left: 1rem;
    }
    .viny_box {
        margin-left: 0;
    }
    .viny_section_two {
        height: auto;
        margin-top: 1rem;
        margin-left: auto;
        left: auto;
        right: auto;
        margin-bottom: 10rem;
    }
    .viny_section_two ul li {
        display: block;
        width: 26.625rem;
    }    
    .viny_section_two ul li:nth-child(4) {
        margin-top: 0;
    }
    .viny_section_two ul li:nth-child(1) {
        margin-top: 0;
    }
    .viny_section_two .car_img {
        margin-left: 0;
        width: 28rem;
        margin-bottom: -3.125rem;
    }
}
.madeeasy_step {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
}

.madeeasy_step li {
    flex: 0 0 100%;
    max-width: 33.33%;
    text-align: center;
}
.madeeasy_step li:first-child {
    text-align: left;
}
.madeeasy_step li:first-child .box-step { 
    margin-right: auto !important;
    margin-left: 0;
}
.madeeasy_step li:last-child {
    text-align: right;
}
.madeeasy_step li:last-child .box-step { 
    margin-left: auto !important;
    margin-right: 0;
}
.madeeasy_step .step-img {
    border-radius: .3125rem;
    overflow: hidden;
    display: flex;
}
.madeeasy_step .step-img img {
    border-radius: .3125rem;
}
.madeeasy_step .box-step {
    width: 282px;
    height: 200px;
    background:transparent;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
}
.madeeasy_step .number {
    background: $primary-color;
    color: #fff;
    border-radius: 50%;
    width: 3.4375rem;
    height: 3.4375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.375rem;
    font-weight: 600;
    top: 50%;
    left: -1.5625rem;
    transform: translateY(-50%);
}
.madeeasy_step .demo-icon {
    background: #fff;
    color: $secondary-color;
    border-radius: 50%;
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.125rem;
    font-weight: 600;
    top: 50%;
    right: -8rem;
    transform: translateY(-50%);
    box-shadow: 0 .3125rem 1.875rem rgba(0, 0, 0, 8%);
}

@media(max-width: 48em) {
    .madeeasy_step li { 
        max-width: 100%;
        margin-bottom: 5rem;
    }
    .madeeasy_step li:first-child .box-step, .madeeasy_step li:last-child .box-step {
        margin: 0 auto;
    }
    .madeeasy_step li .box-step {
        margin: 0 auto;
    }
    .madeeasy_step .number {
        width: 2.5rem;
        height: 2.5rem;
        left: 0;
        font-size: 1rem;
        right: 0;
        top: 0px;
        margin: 0 auto;
    }
    .madeeasy_step .demo-icon {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: -.5rem;
        top: auto;
        transform: rotate(90deg);
    }
}

@media(max-width: 411px) {
    .madeeasy_step li {
        max-width: 100%;
        margin-bottom: 5rem;
    }
    .madeeasy_step li:first-child .box-step, .madeeasy_step li:last-child .box-step {
        margin: 0 auto;
    }
    .madeeasy_step li .box-step {
        margin: 0 auto;
    }
    .madeeasy_step .number {
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
    }
    .madeeasy_step .demo-icon {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: -.5rem;
        top: auto;
        transform: rotate(90deg);
    }
}

.download-container {
    margin: 3rem 0;
}
.download-link h3 {
    font-weight: 600;
}
.download-link a {
    color: blue;
    font-size: 1rem;   
}
.download-link p {
   font-size: 2rem;
   line-height: 2.5rem;
}
.quote_box {
    position: relative;
    background-color: $primary-color;
    min-height: 20.5rem;
    margin-top: 2rem;
    margin-bottom: 0px;
}

/*
.quote_box .img_quote {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
    max-height: 20.5rem;
} 
*/

.quote_box p {
    position: relative;
    z-index: 2;
    font-size: 2.25rem;
    text-align: center;
    color: #fff !important;
    width: 100%;
    margin: 0 auto;
    font-weight: 400;
    line-height: 3.5rem;
}
.howtoinstall_box ul {
    padding-left: 4.375rem;
}
.howtoinstall_box ul li {
    position: relative;
    display: inline-block;
}
.howtoinstall_box ul li:before {
    content: "";
    border-left: .125rem dashed $primary-color;
    position: absolute;
    height: calc(100% + 2.5rem);
    left: -2.875rem
}
.howtoinstall_box ul li .number_round {
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    background: $primary-color;
    line-height: 3.125rem;
    color:#fff;
    font-size: .9375rem;
    text-align: center;
    font-weight: 600;
    left: -4.375rem;
    border-radius: 6.25rem;
}

// sign-designer
.sign-designer {
    overflow: visible;
}
.mounting-label {
    display: block !important;
}
.sign-designer thead tr th:nth-child(2),
.sign-designer tbody tr td:nth-child(2) {
    width: 20rem;
}
.sign-designer thead tr th:nth-child(3),
.sign-designer tbody tr td:nth-child(3),
.sign-designer thead tr th:nth-child(4),
.sign-designer tbody tr td:nth-child(4) {
    width: 12rem;
}

@media screen and (max-width: 37.5em), (max-width: 48em)  {
    .sign-designer thead tr th:nth-child(2),
    .sign-designer tbody tr td:nth-child(2) {
        width: 12rem;
    }
    .sign-designer thead tr th:nth-child(3),
    .sign-designer tbody tr td:nth-child(3),
    .sign-designer thead tr th:nth-child(4),
    .sign-designer tbody tr td:nth-child(4) {
        width: 8rem;
    }
}
@media screen and (max-width: 25.6875em) {
    .sign-designer thead tr th:nth-child(2),
    .sign-designer tbody tr td:nth-child(2) {
        width: 20rem;
    }
    .sign-designer thead tr th:nth-child(3),
    .sign-designer tbody tr td:nth-child(3),
    .sign-designer thead tr th:nth-child(4),
    .sign-designer tbody tr td:nth-child(4) {
        width: 20rem;
    }
}
.palette {
    float: left;
    border: 1px solid #757575;
    padding: 0;
    width: 2.25em;
    height: 2.5em;
    margin: .0625em;
}
.palettecolor {
    display: inline-block;
    text-decoration: none;
    width: 2.25em;
    height: 2.5em;
    padding: .125em .375em;
}
.sign-designer-order-box {
    font-size: 1.5rem;
    font-weight: 500;
}

// Misc Pages
.mm10_login_title_container {
    position: relative;
    display: block;
    margin-bottom: 1rem;
    color: #494a53;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.login-header {
    font-size: 3rem;
    line-height: 110%;
    font-weight: 500;
    color: black;
    margin: 1.94667rem 0 1.168rem 0;
}

.login-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #757575;
}

// ordl
.fa.fa-print, .mi-print {
    color: inherit;
    font-size: inherit;
}

@media screen and (max-width: 37.5em){
    .g-recaptcha {
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
    }
}